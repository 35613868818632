export interface ViewsSettings extends Record<string, any> {
  auth_login_image?: FileItem;
  auth_recover_image?: FileItem;
  auth_reset_image?: FileItem;
  auth_confirm_image?: FileItem;
  auth_register_image?: FileItem;

  page_contact_image?: FileItem;
  page_contact_image_success?: FileItem;
}

export const useViewsSettings = createSharedComposable(() => {
  const { getSingletonItem } = useDirectusItems();

  const { data: settings } = useAsyncData(
    "settings",
    async () =>
      await getSingletonItem<ViewsSettings>({
        collection: "views_settings",
        params: { fields: ["*", "*.*"] },
      }),
    { immediate: true }
  );

  const getAuthViewImage = (view: MaybeRef<AuthView>) => {
    switch (unref(view)) {
      case AuthView.Login:
        return settings.value?.auth_login_image;
      case AuthView.Recover:
        return settings.value?.auth_recover_image;
      case AuthView.Reset:
        return settings.value?.auth_reset_image;
      case AuthView.Confirm:
        return settings.value?.auth_confirm_image;
      case AuthView.Register:
        return settings.value?.auth_register_image;
    }
  };

  const getContactPageImage = (success: MaybeRef<boolean>) => {
    return unref(success)
      ? settings.value?.page_contact_image_success
      : settings.value?.page_contact_image;
  };

  return { settings, getAuthViewImage, getContactPageImage };
});
